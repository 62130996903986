import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'
import VersionList from '../../components/VersionsList'

class twoPointOne extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')

    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />

        <section className="my-5 py-5 bg-gray">
          <h1 className="text-center my-4">
            Third Party Software - Lenses 2.1
          </h1>
        </section>

        <section className="my-5 py-5">
          <div className="container-1">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <b>Please choose the version of lenses: </b>

                <VersionList />

                <h4 className="underline">Third Party Libraries : Lenses</h4>

                <p>
                  The table below lists third party software that is
                  incorporated into <b>Lenses</b>, along with the license
                  associated with the software.
                </p>

                <table className="table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="entry w-40 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        JVM Project
                      </th>
                      <th
                        className="entry w-60 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">akka/actor</td>
                      <td className="text-center">
                        <a
                          href="https://github.com/akka/akka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">akka/http</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka-http"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">akka/sse</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hseeberger/akka-sse"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">akka/reactive-kafka</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/reactive-kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/avro</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/avro"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/calcite</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/calcite-avatica</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite-avatica"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-cli</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-cli"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-codec</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-codec"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        apache/commons-collections
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-collections"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-compress</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-compress"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-io</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-lang</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-lang"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-logging</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-logging"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-net</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-net"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-pool</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-pool"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/curator</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/curator"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/log4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/log4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpclient</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpcomponents-client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpcore</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpcomponents-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/zookeeper</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/zookeeper"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/servicemix-specs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/servicemix-specs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/velocity-engine</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/velocity-engine"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache-xml/xalan-j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://svn.apache.org/viewvc/xalan/java/trunk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache-xml/xml-apis</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://xerces.apache.org/xml-commons/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/santuario/xmlsec</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/santuario-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/connect-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/connect-json</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/kafka-clients</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/kafka-streams</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">confluent/common</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/common"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-avro-serializer
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-connect-avro-converter
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-schema-registry-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    {/* <!-- Introduced in 1.1 --> */}
                    <tr>
                      <td className="text-center">
                        fabric8io/kubernetes-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fabric8io/kubernetes-client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    {/* <!-- Introduced in 1.1 --> */}
                    <tr>
                      <td className="text-center">
                        fabric8io/kubernetes-model
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fabric8io/kubernetes-model"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    {/* <!-- Introduced in 1.1 --> */}
                    <tr>
                      <td className="text-center">mifmif/generex</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/mifmif/Generex"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-annotations
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-annotations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">fasterxml/jackson-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-databind
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-databind"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-dataformat-yaml
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-dataformats-text"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-module-paranamer
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-modules-base"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-module-scala
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-module-scala"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">fasterxml/woodstox</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/woodstox"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">opensaml/opensaml-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-messaging-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-messaging-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-profile-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-profile-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-saml-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-saml-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-security-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-security-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-soap-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-soap-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-storage-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-xmlsec-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        opensaml/opensaml-xmlsec-impl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.shibboleth.net/view/?p=java-opensaml.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">zkclient</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sgroschupf/zkclient"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/gson</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/gson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/guava</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/guava"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    {/* <!-- Introduced in 1.1 --> */}
                    <tr>
                      <td className="text-center">scala-graph/scala-graph</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala-graph/scala-graph"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">codehaus/jackson</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/codehaus/jackson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jodaorg/joda-convert</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JodaOrg/joda-convert"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jodaorg/joda-time</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JodaOrg/joda-time"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-ast</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s-ast"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">pac4j/pac4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pac4j/pac4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">pac4j/pac4j-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pac4j/pac4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">pac4j/pac4j-saml</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pac4j/pac4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafehub/config</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typesafehub/config"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        spring-projects/spring-framework
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-framework"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        spring-projects/spring-ldap
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-ldap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafehub/scala-logging</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typesafehub/scala-logging"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafehub/ssl-config</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typesafehub/ssl-config"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/macro-compat</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/milessabin/macro-compat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">dropwizard/metrics-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/dropwizard/metrics"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">milessabin/shapeless</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/milessabin/shapeless"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">org.yaml/snakeyaml</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://bitbucket.org/asomov/snakeyaml"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">snappy/snappy-java</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/xerial/snappy-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">spray/spray-json</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spray/spray-json"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">netty/netty</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/netty/netty"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">nscala-time</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/nscala-time/nscala-time"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">square/okhttp</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/square/okhttp"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">square/okio</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/square/okio"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jose4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://bitbucket.org/b_c/jose4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">humanize</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/mfornos/humanize"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">prettytime</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ocpsoft/prettytime"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">lz4</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lz4/lz4-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">xz</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.tukaani.org/?p=xz-java.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Public
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">avro4s</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/avro4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jslack</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/seratch/jslack"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">unidecode</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/xuender/unidecode"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">hasher</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Nycto/Hasher"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">slf4j/slf4j-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/slf4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/java8-compat</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-java8-compat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/parser-combinators</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-parser-combinators"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala-xml</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-xml"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">thoughtworks/paranamer</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/paul-hammant/paranamer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">rocksdb/jni</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fusesource/rocksdbjni"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/protobuf</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/protobuf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    {/* <!-- Introduced in 1.1 --> */}
                    <tr>
                      <td className="text-center">
                        dk.brics.automaton/automaton
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/cs-au-dk/dk.brics.automaton"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">cryptacular</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/vt-middleware/cryptacular"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: Apache 2.0, LGPL
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jboss-javassist/javassist</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jboss-javassist/javassist"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">javax.ws/rs-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jax-rs/api/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: CDDL, GPL1.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">logback/logback-classic</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/logback"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: EPL1, LGPL2.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">logback/logback-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/logback"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: EPL1, LGPL2.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        reactive-streams/reactive-streams
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reactive-streams/reactive-streams-jvm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Create Commons CC0
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="my-4">
                  The table below lists third party software that is
                  incorporated into <b>Lenses UI</b>, along with the license
                  associated with the software.
                </p>

                <table className="w-100 table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="entry text-center w-40"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        JS Project
                      </th>
                      <th
                        className="entry w-60 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">ace-diff</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/benkeen/ace-diff"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-animate</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-aria</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-base64</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ninjatronic/angular-base64"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-diff-match-patch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/amweiss/angular-diff-match-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-google-chart</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-google-chart/angular-google-chart"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-material</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://material.angularjs.org/latest/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        angular-material-data-table
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/daniel-nagy/md-data-table"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-route</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-sanitize</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-spinner</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/urish/angular-spinner"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-storage</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/auth0/angular-storage"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-ui-ace</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-ui/ui-ace"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-ui-bootstrap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-ui/bootstrap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-utils-pagination</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/michaelbromley/angularUtils-pagination"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-visjs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/visjs/angular-visjs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angularjs-slider</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-slider/angularjs-slider"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">bootstrap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/twbs/bootstrap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">brace</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/thlorenz/brace"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">event-source-polyfill</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Yaffle/EventSource.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">file-saver</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/eligrey/FileSaver.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">handsontable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/handsontable/handsontable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jquery</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jquery/jquery"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Stuk/jszip"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip-utils</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Stuk/jszip-utils"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">moment</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/moment/moment"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-handsontable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/handsontable/ngHandsontable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-redux</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-redux/ng-redux"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-redux-router</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/neilff/redux-ui-router"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ngletteravatar</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/uttesh/ngletteravatar"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reactjs/redux"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-action-buffer</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rt2zz/redux-action-buffer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-logger</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/evgenyrodionov/redux-logger"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-persist</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rt2zz/redux-persist"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">reduxsauce</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/infinitered/reduxsauce"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">underscore</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jashkenas/underscore"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ace-builds</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ajaxorg/ace-builds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-chart.js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jtblin/angular-chart.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">seamless-immutable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rtfeldman/seamless-immutable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-json-tree</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/awendland/angular-json-tree"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Creative Commons
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">humanize-duration</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/EvanHahn/HumanizeDuration.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Public
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">avro-js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://svn.apache.org/repos/asf/avro/trunk/lang/js/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">diff-match-patch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://code.google.com/archive/p/google-diff-match-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">rxjs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ReactiveX/RxJS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">vis</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/almende/vis"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">font-awesome</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://fontawesome.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          OFL 1.1
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-1">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h4>Website Icons</h4>
                from{' '}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com{' '}
                </a>
                is licensed by{' '}
                <a
                  href="http://creativecommons.org/licenses/by/3.0/"
                  title="Creative Commons BY 3.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC 3.0 BY
                </a>
                <ul>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/smashicons"
                        title="Smashicons"
                      >
                        Smashicons
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/eucalyp"
                        title="Eucalyp"
                      >
                        Eucalyp
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/pixel-buddha"
                        title="Pixel Buddha"
                      >
                        Pixel Buddha
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/monkik"
                        title="monkik"
                      >
                        monkik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a href="http://www.freepik.com" title="Freepik">
                        Freepik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/surang"
                        title="surang"
                      >
                        surang
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/geotatah"
                        title="geotatah"
                      >
                        geotatah
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default twoPointOne

export const pageQuery = graphql`
  query ThirdPartySofware21SEO {
    allContentfulPages(
      filter: {
        pageReference: { pageIdentifier: { eq: "Third_Party_Software_2.1" } }
      }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
